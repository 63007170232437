/**
 * IE Styles
 *
 */

	#page-nav ul {
		display: block;

		li {
			display: inline-block;
			min-width: 10%;
		}

	}

	@include old-ie {
		#page-nav {
			ul {
				display: block;
			}

			ul > li {
				float: left;
				width: 12.5%;
			}
		}
	}

/*
 * ================================
 * 	_Forms
 * ================================
 */

	@include old-ie {
		input[type="text"],
		input[type="password"],
		input[type="datetime"],
		input[type="datetime-local"],
		input[type="date"],
		input[type="month"],
		input[type="time"],
		input[type="week"],
		input[type="number"],
		input[type="email"],
		input[type="url"],
		input[type="search"],
		input[type="tel"],
		input[type="color"],
		textarea,
		select {
			padding: em(8px) 1%;
			height: 20px;
			width: 98%;
		}

		input[type="radio"],
		input[type="checkbox"] {
			margin-top: 1px \9;
			*width: 13px;
			*height: 13px;
		}

		select {
			width: 98%;
		}

		textarea {
			height: 125px;
		}

		.form-horizontal .controls:first-child {
			padding-left: 280px;
		}

		.field-wrap {
			clear: both;
			float: left;
			width: 100%;
		}

		input.btn,
		a.btn,
		.btn {
			margin-left: .3em;
			display: inline;
			zoom: 1;

			&:first-child {
				margin-left: 0;
			}
		}

		.radio input[type="radio"],
		.radio-inline input[type="radio"],
		.checkbox input[type="checkbox"],
		.checkbox-inline input[type="checkbox"] {
			margin-top: 4px \9;
		}
	}

/*
 * ================================
 * 	_Grid
 * ================================
 */

	@include old-ie {
		.row,
		.form-row,
		.inside {
			zoom: 1;

			[class*="span"] {
				margin-left: 2.074468085106383%;
			}


			.twelve, .span12 {
				width: 99.94680851063829%;
			}

			.eleven, .span11 {
				width: 91.43617021276594%;
			}

			.ten, .span10 {
				width: 82.92553191489361%;
			}

			.nine, .span9 {
				width: 74.41489361702126%;
			}

			.eight, .span8 {
				width: 65.90425531914893%;
			}

			.seven, .span7 {
				width: 57.39361702127659%;
			}

			.six, .span6 {
				width: 48.88297872340425%;
			}

			.five, .span5 {
				width: 40.37234042553192%;
			}

			.four, .span4 {
				width: 31.861702127659576%;
			}

			.three, .span3 {
				width: 23.351063829787233%;
			}

			.two, .span2 {
				width: 14.840425531914894%;
			}

			.one, .span1 {
				width: 6.329787234042553%;
			}
		}
	}

